import React from 'react';
import { setEmployerPensionYou } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const EmployerPensionAmountYou = ( props ) => {

    const onChange = value => {
        props.setEmployerPensionYou(value)
    };

    return (
        <InputStep
            label={'Monthly Employer'}
            onChange={onChange}
            max={100000}
            total={props.employerPensionTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        employerPensionTotal: state.sourceOfIncome.you.employerPension.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setEmployerPensionYou: (val) => dispatch(setEmployerPensionYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerPensionAmountYou);