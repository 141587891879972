import React, { useEffect, useCallback, useState } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import EmployerPensionAmountYou
    from "../../../components/ContentRow/Step4/EmployerPensionAmount/EmployerPensionAmountYou";
import EmployerPensionAmountSpouse
    from "../../../components/ContentRow/Step4/EmployerPensionAmount/EmployerPensionAmountSpouse";
import { fourthStepCompleted } from "../../../store/actions/SourceOfIncomeActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const EmployerPensionAmount = ( props ) => {
    const titles = {
        layoutTitle: 'EMPLOYER PENSION ',
        sectionTitle: 'How much will you receive monthly?',
    }

    const title = "Monthly pension in retirement"

    const [next, setNext] = useState(retirementCalcLinks.sourceOfIncomeSummary)

    const nextLink = useCallback(() => {
        if(props.sourceOfIncomeYou.other.exist || props.sourceOfIncomeSpouse.other.exist){
            return retirementCalcLinks.otherAmount;
        }else {
            return retirementCalcLinks.sourceOfIncomeSummary
        }
    }, [props]);

    useEffect(() => {
        props.fourthStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep4={(4/6)*100}
            active={'step4'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.sourceOfIncomeYou.employerPension.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <EmployerPensionAmountYou/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.sourceOfIncomeSpouse.employerPension.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <EmployerPensionAmountSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        sourceOfIncomeYou: state.sourceOfIncome.you,
        sourceOfIncomeSpouse: state.sourceOfIncome.spouse,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fourthStepCompleted: (val) => dispatch(fourthStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerPensionAmount);