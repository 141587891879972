import React from 'react';
import { setEmployerPensionSpouse } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const EmployerPensionAmountSpouse = ( props ) => {

    const onChange = value => {
        props.setEmployerPensionSpouse(value)
    };

    return (
        <InputStep
            label={'Monthly Employer'}
            onChange={onChange}
            max={100000}
            total={props.employerPensionTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        employerPensionTotal: state.sourceOfIncome.spouse.employerPension.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setEmployerPensionSpouse: (val) => dispatch(setEmployerPensionSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerPensionAmountSpouse);